import React from "react";
import { graphql } from 'gatsby';
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ReactMarkdown from "react-markdown";
import Layout from "../layouts/headerFooterOnly";

class RequestDemo extends React.Component {
  render() {
    let {
      data: {
        datoCmsRequestDemo: {
          pageDescription,
          feature,
          seoMetaTags
        }
      }
    } = this.props;

    resetWebflow("5aabd62c51e22eee8d346d1a");

    const featureData = feature.filter(
      block => block.model && block.model.apiKey === "feature"
    );
    const featureTmpl = featureData.map((block, i) => {
      return (
        <React.Fragment>
          <div className="flex-container start">
            <div
              className="outline-icon"
              style={{ background: `url(${block.image.url})` }}
            />
            <div className="content-w-outline-icon">
              <div className="content-left">{block.title}</div>
            </div>
          </div>
          {(i !== featureData.length - 1) && <div className="sm-spacing" />}
        </React.Fragment>
      );
    });
    const clientLogos = feature.filter(
      block => block.model && block.model.apiKey === "client_logo"
    )[0];
    const clientLogosTmpl = clientLogos.logos.map(block => {
      return (
        <div key={block.id}>
          <img src={block.url} alt={block.alt} />
        </div>
      );
    });
    return (
      <Layout>
        <HelmetDatoCms seo={seoMetaTags} />
        <section id="demo" className="white-section centered full-demo">
          <div className="container demo">
            <div className="sm-spacing _30" />
            <iframe
              border="0px"
              height="800px"
              src="https://heyflow.id/website-lead"
              style={{ border: 'none' }}
              width="100%"
            />
          </div>
        </section>
      </Layout>
    );
  }
}

export default RequestDemo;

export const query = graphql`
  query RequestDemo {
    datoCmsRequestDemo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      pageDescription
      feature {
        ... on DatoCmsFeature {
          id
          title
          image {
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsClientLogo {
          logos {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`;
